import axios, { AxiosResponse } from 'axios';
import { WebCart, WebCartItemsLookup, WebCartItem, AddToCartModalConfig, WebCartCurrentDelivery } from '@/models/Cart';
import { CsvImportModel, CsvImportResultModel } from '@/models/CsvImport';

export default class CartService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/cart`;
    }

    public getCartModel(args: any = {}): WebCart {
        return {
            items: args.items ?? [],
            fees: args.fees ?? [],
            displaytotalprice: args.displaytotalprice ?? {},
            itemcount: 0
        }
    }

    public getCartCurrentDeliveryModel(): WebCartCurrentDelivery {
        return {
            freedeliverypossible: false,
            freedeliveryobtained: false,
            displayfreedeliverylimit: null,
            displayfreedeliverydiff: null
        };
    }

    public getCartItemLookupModel(args: any = {}): WebCartItemsLookup {
        return args;
    }

    public getAddToCartModalConfig(args: any = {}): AddToCartModalConfig {
        return {
            productId: args.productId ?? null,
            variantId: args.variantId ?? null,
            quantity: args.quantity ?? null,
            show: args.show ?? false,
            showRelatedProducts: args.showRelatedProducts ?? false,
        };
    }

    public createLookup(cart: WebCart): WebCartItemsLookup {
        return cart?.items.reduce((output: WebCartItemsLookup, item: WebCartItem) => {
            output[`${item.productid}###${item.variantid ?? ''}`] = item;
            return output;
        }, {});
    }

    public async getCart(): Promise<WebCart> {
        try {
            const res: AxiosResponse<WebCart> = await axios.get(`${this.endpoint}/getcart`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getCartRelatedProducts(limit: number = 10): Promise<string[]> {
        try {
            const res: AxiosResponse<string[]> = await axios.get(`${this.endpoint}/getrelatedproducts?limit=${limit}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }    

    public async getCartCurrentDelivery(): Promise<WebCartCurrentDelivery> {
        try {
            const res: AxiosResponse<WebCartCurrentDelivery> = await axios.get(`${this.endpoint}/getcurrentdelivery`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async addOrUpdateCart(args: any): Promise<WebCart> {
        try {
            const res: AxiosResponse<WebCart> = await axios.post(`${this.endpoint}/addorupdatecart`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async addOrUpdateCartFromList(args: any): Promise<WebCart> {
        try {
            const res: AxiosResponse<WebCart> = await axios.post(`${this.endpoint}/addorupdatecartfromlist`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }   
    
    public async emptyCart(): Promise<boolean> {
        try {
            const url = `${ this.endpoint }/emptycart`;
            await axios.get(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    public async changeCart(merge: boolean): Promise<boolean> {
        try {
            let url = `${ this.endpoint }/change`;
            if (merge) {
                url += '?merge=true';
            }
            await axios.get(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    public async getRetentionCartItems(): Promise<WebCartItem[]> {
        try {
            const res: AxiosResponse<WebCart> = await axios.get(`${this.endpoint}/getretentioncart`);
            return res.data.items;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    public async csvImportToCart(args: CsvImportModel): Promise<CsvImportResultModel> {
        try {
            const res: AxiosResponse<CsvImportResultModel> = await axios.post(`${this.endpoint}/csvimporttocart`, args);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
    
    public async facebookApiTrackAddToCart(prodid: string, eventUrl: string) {
        try {
            const res: AxiosResponse<string> = await axios.get(`${this.endpoint}/fbapiaddtocart?prodid=${prodid}&eventurl=${eventUrl}`)
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getKlaviyoAddToCartJson(prodid: string, qty: number): Promise<string> {
        try {
            const res: AxiosResponse<string> = await axios.get(`${this.endpoint}/getklaviyoaddtocartjson?prodid=${prodid}&qty=${qty}`)
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
}
