
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsItemnumber',
    components: {
    },
})
export default class DetailsItemnumber extends Vue {

    @Prop({ type: String, required: true }) private itemNoText: string;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get itemnumber(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.sku;
        else 
            return ProductDetailsModule.PRODUCT?.id ?? '';
    }

    get hidden(): boolean {
        return ProductDetailsModule.VARIANTS?.length > 0 && CbxVariablesModule.VARIABLES.variantDisplayOptions.productlist;
    }
}
