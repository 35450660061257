
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayPriceInfo } from '../../models/Product';
import DetailsDuties from './DetailsDuties.vue';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsPrice',
    components: {
        DetailsDuties,
    },
})
export default class DetailsPrice extends Vue {
    @Prop({ type: String, required: true }) private stsText: string;
    @Prop({ type: String, required: true }) private exvatText: string;
    @Prop({ type: String, required: true }) private incvatText: string;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get loading(): boolean {
        return ProductDetailsModule.IS_LOADING;
    }

    get displayPriceInfo(): DisplayPriceInfo {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.price;
        else 
            return ProductDetailsModule.PRODUCT?.price;
    }

    get price(): string {
        if (this.displayPriceInfo.showpricesincvat)
            return this.displayPriceInfo.priceincvat;
        else
            return this.displayPriceInfo.priceexvat;
    }

    get vatPrice(): string {
        if (!this.displayPriceInfo.showpricesincvat)
            return this.displayPriceInfo.priceincvat;
        else
            return this.displayPriceInfo.priceexvat;
    }    

    get vatDesc() {
        if (!this.displayPriceInfo.showpricesincvat)
            return this.incvatText;
        else
            return this.exvatText;
    }

    get unit(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.unit;
        else 
            return ProductDetailsModule.PRODUCT?.unit;
    }
}
