const UIkit = window.UIkit;
import axios from "axios";
import Vue from 'vue';

export default function () {
    UIkit.util.on(document, 'beforeshow', '#main-navigation .uk-navbar-dropdown', function (e) {

        const elem = e.target,
            id = elem.dataset.id,
            type = elem.dataset.type,
            dropdowngrid = document.querySelector('#main-navigation [data-id="' + elem.dataset.id + '"] .uk-navbar-dropdown-grid'),
            translations = window.CbxTranslations;

        // const chunkArray = (array, chunkSize) => Array(Math.ceil(array.length / chunkSize))
        //         .fill()
        //         .map((_, index) => index * chunkSize)
        //         .map(begin => array.slice(begin, begin + chunkSize));

        axios.get('/contextapi/' + window.CbxApiContextKey + '/v1/' + (type === 'Content' ? 'content' : 'productcategories') + '/childrenexpanded/' + id)
            .then(function (response) {

                let html = '';

                const limit = 80;
                let showMore = false;
                if (response.data.children.length > limit)
                {
                    showMore = true;
                    response.data.children = response.data.children.splice(0, limit);
                }

                if (type === "Content")
                {
                    html += `
                    <div>
                        <ul class="uk-nav uk-navbar-dropdown-nav">`;

                    response.data.children.forEach(item => {
                        const active = false;
                        html += `
                            <li class="${(active ? 'uk-active' : '')}">
                                <a href="${item.link}">${item.title}</a>
                            </li>
                        `;
                    });

                    html += `
                        </ul>
                    </div>`;
                }
                else
                {
                    // group children into 4 columns
                    
                    html += '<div class="category-dropdown uk-container uk-container-large">';


                    // const colSize = Math.ceil(response.data.children.length / 4);
                    // const chunks = chunkArray(response.data.children, colSize);
                    response.data.children.slice(0, 5).forEach((child) => {
                        html += `
                            <div class="category-dropdown-item">
                                <a href="${child.link}" class="category-dropdown-parent">
                                    <!-- <img src="https://superhelten.b-cdn.net/media/catalog/category/Figurer_og_leges_t_-_kategori_.jpg" />-->
                                    <div class="category-dropdown-parent-text">
                                        ${child.title}
                                    </div>
                                </a>
                                <div>`;
                                
                        child.children.forEach((grandChild) => {
                            html += `<div class="category-dropdown-item-child}">
                                        <category-expander-item
                                            id="${ grandChild.id }"
                                            label="${ grandChild.title }"
                                            url="${ grandChild.link }"
                                            :haschildren="${ grandChild.haschildren }">
                                        </category-expander-item>
                                        <!-- <a style="display: block" href="${ grandChild.link }">${ grandChild.title }</a> -->
                                    </div>`;
                        });
                        html += '</div></div>';
                    });


                    // chunks.forEach(chunk => {
                    //     html += `<div>`;
                    //     chunk.forEach(item => {
                    //         const active = false;
                    //         html += `
                    //         <div>
                    //             <ul class="uk-nav uk-navbar-dropdown-nav">
                    //             <li class="${(active ? 'uk-active' : '')}">
                    //                 <a href="${item.link}">${item.title}</a>
                    //             </li>
                    //             </ul>
                    //         </div>
                    //         `;
                    //     });
                    //     html += `</div>`;
                    // });

                }

                if (showMore)
                html += `<div class="uk-width-1 uk-text-center"><a class="uk-button uk-button-default" href="${response.data.parent.link}">${translations?.actions?.showMore ?? 'Show more'} <span uk-icon="icon: arrow-right"></span></a></div>`;

                dropdowngrid.innerHTML = html;

                const grandChildrenWithChildren = document.querySelectorAll('.category-dropdown-item-child');
                if (grandChildrenWithChildren) {
                    const extended = Vue.extend(window.app);
                    new extended({
                        // i18n: (window as any).app.$i18n,
                        // data: (window as any).app.$data,
                        mounted: function() {
                            this.$nextTick(function () {
                                // if (typeof callback === 'function')
                                //     callback();
                            })
                        }
                    }).$mount(document.querySelector('.category-dropdown'));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    });
}